<template>
  <div>
    <page-header>
      {{ $t('SKUManage') }}
    </page-header>
    <template v-if="isLoading">
      <div
        class="mt-4"
        style="display: grid;grid-gap: 8px;width: 100%; grid-template-columns: repeat(auto-fill,minmax(250px,1fr));"
      >
        <v-card
          class="pa-4"
          elevation="0"
          outlined
          @click="SKUDialog = true"
        >
          <v-responsive
            :aspect-ratio="1"
          >
            <div
              class="d-flex align-center justify-center flex-column"
              style="height: 100%"
            >
              <v-icon large>
                mdi-plus
              </v-icon>
              <div class="text-center">
                {{ $t('newSKU') }}
              </div>
            </div>
          </v-responsive>
        </v-card>
        <v-card
          v-for="i in SKUList"
          :key="i.id"
          class="pa-2"
          color="#eee"
          elevation="0"
          outlined
          @click="checkSKUItem(i)"
        >
          <v-responsive
            :aspect-ratio="1"
            style="position: relative"
          >
            <div class="d-flex">
              <v-spacer/>
              <div>
                <v-icon @click.stop="deleteIngredient(i)">
                  mdi-delete
                </v-icon>
              </div>
            </div>
            <div
              class="d-flex align-center justify-center flex-column mt-2"
            >
              <div class="text-h5 font-weight-bold">
                <div>
                  {{ i.name }}
                </div>
              </div>
            </div>
            <div class="d-flex align-center justify-center mt-6">
              {{ $t('numberOfIngredient') }}:{{ i.attrCount }}
            </div>
          </v-responsive>
        </v-card>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex align-center justify-center"
        style="height: 400px"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </div>
    </template>
    <v-dialog
      v-model="SKUDialog"
      max-width="600px"
      persistent
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <template v-if="editItemID > 0">
            <div class="text-h3 font-weight-bold">
              {{ $t('editSKU') }}
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              {{ $t('newSKU') }}
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="SKUDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div>
                <div>  {{ $t('name') }}</div>
                <v-text-field
                  v-model="SKUName"
                  dense
                  hide-details
                  outlined
                />
              </div>
              <div class="mt-2">
                <div>{{ $t('SKU') }}</div>
                <v-text-field
                  v-model="SKUNumber"
                  dense
                  hide-details
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <div>{{ $t('price') }}</div>
                <div class="text-caption">
                  {{ $t('keepTowDecimal') }}
                </div>
                <v-form
                  ref="priceForm"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="price"
                    :rules="priceRules"
                    dense
                    outlined
                    type="number"
                  />
                </v-form>
              </div>
              <div class="mt-2">
                <div>{{ $t('newContent') }}</div>
                <v-text-field
                  v-model="amount"
                  dense
                  hide-details
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>{{ $t('unitPrice') }}</div>
              <div class="d-flex">
                <div>
                  <div class="text-caption">
                    {{ $t('price') }}
                  </div>
                  <v-form
                    ref="unitPriceForm"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="unitPrice"
                      :rules="priceRules"
                      dense
                      outlined
                      style="width: 100px"
                      type="number"
                    />
                  </v-form>
                </div>
                <div class="ml-6 mt-6">
                  /
                </div>
                <v-spacer/>
                <div>
                  <div class="text-caption">
                    {{ $t('unit') }}
                  </div>
                  <v-text-field
                    v-model="unit"
                    dense
                    outlined
                    style="width: 100px"
                  />
                </div>
              </div>
              <div class="mt-2">
                <div>{{ $t('numberOfIngredient') }}</div>
                <v-text-field
                  v-model="attrCount"
                  dense
                  hide-details
                  outlined
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex">
          <v-spacer/>
          <template v-if="editItemID > 0">
            <v-btn
              class="primary  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="sureEdit"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="primary  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { addSKU, deleteSKU, editSKU, getSKUList } from '@/common/Utlis/api'
  import { showSuccessMessage, sureToDelete } from '@/common/utils'
  import PageHeader from '@/composable/InK/typography/PageHeader'

  export default {
    name: 'SettingSKU',
    components: { PageHeader },
    data: function () {
      return {
        unit: null,
        priceRules: [
          v => (v.split('.')[1].length <= 2) || this.$t('keepTowDecimal'),
        ],
        attrCount: null,
        isLoading: false,
        valid: true,
        editItemID: -1,
        SKUDialog: false,
        SKUName: null,
        SKUNumber: null,
        price: null,
        unitPrice: null,
        amount: null,
        SKUList: null,
      }
    },
    watch: {
      SKUDialog (val) {
        if (!val) {
          this.editItemID = -1
          this.SKUName = ''
          this.SKUNumber = ''
          this.price = ''
          this.unitPrice = ''
          this.unit = ''
          this.amount = ''
          this.attrCount = ''
        }
      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      async deleteIngredient (item) {
        await sureToDelete(async () => {
          await deleteSKU(item.id)
          showSuccessMessage(this.$t('delete_success'))
          await this.reload()
        })
      },
      async sureEdit () {
        await editSKU({
          id: this.editItemID,
          name: this.SKUName,
          sku: this.SKUNumber,
          price: this.price,
          unitPrice: this.unitPrice + ' / ' + this.unit,
          amount: this.amount,
          attrCount: this.attrCount,
        })
        this.SKUDialog = false
        showSuccessMessage()
        await this.reload()
      },
      async checkSKUItem (item) {
        const res = item.unitPrice.split(' / ')
        this.unitPrice = res[0]
        this.unit = res[1]
        this.SKUName = item.name
        this.SKUNumber = item.sku
        this.price = item.price
        this.amount = item.amount
        this.editItemID = item.id
        this.attrCount = item.attrCount
        this.SKUDialog = true
      },
      async save () {
        await addSKU({
          attrCount: this.attrCount,
          name: this.SKUName,
          sku: this.SKUNumber,
          price: this.price,
          unitPrice: this.unitPrice + ' / ' + this.unit,
          amount: this.amount,
        })
        this.SKUDialog = false
        showSuccessMessage()
        await this.reload()
      },
      async reload () {
        this.isLoading = false
        this.SKUList = await getSKUList()
        this.isLoading = true
      },
    },
  }
</script>

<style scoped>

</style>
